import service from './service'
import { CONTENT_TYPE } from '@/constants'
import { useUserStoreWithOut } from '@/store/modules/user'
import { ElMessage } from 'element-plus'
import { useUserStore } from '@/store/modules/user' // 假设你有一个 auth store
import { getActivePinia } from 'pinia'

// 退出登录的逻辑
const logout = () => {
  ElMessage({
    message: 'Login token has expired, please log in again',
    type: 'warning',
    duration: 2000, // 2秒后自动关闭
    onClose: () => {
      // 2秒后执行退出登录操作
      // 确保 Pinia 已经初始化
      if (getActivePinia()) {
        const authStore = useUserStore()
        authStore.logout() // 调用 auth store 中的 logout 方法
      } else {
        console.error('Pinia is not initialized. Cannot perform logout.')
      }
    }
  })
}

const request = async (option: AxiosConfig) => {
  const { url, method, params, data, headers, responseType } = option

  const userStore = useUserStoreWithOut()
  try {
    const response = (await service.request({
      url: url,
      method,
      params,
      data: data,
      responseType: responseType,
      headers: {
        'Content-Type': CONTENT_TYPE,
        [userStore.getTokenKey ?? 'Authorization']: userStore.getToken ?? '',
        ...headers
      }
    })) as any

    if (response?.data?.code === 401) {
      logout()
      throw new Error('Unauthorized')
    }

    return response
  } catch (error) {
    throw error
  }
}

export default {
  get: <T = any>(option: AxiosConfig) => {
    return request({ method: 'get', ...option }) as Promise<IResponse<T>>
  },
  post: <T = any>(option: AxiosConfig) => {
    return request({ method: 'post', ...option }) as Promise<IResponse<T>>
  },
  delete: <T = any>(option: AxiosConfig) => {
    return request({ method: 'delete', ...option }) as Promise<IResponse<T>>
  },
  put: <T = any>(option: AxiosConfig) => {
    return request({ method: 'put', ...option }) as Promise<IResponse<T>>
  },
  cancelRequest: (url: string | string[]) => {
    return service.cancelRequest(url)
  },
  cancelAllRequest: () => {
    return service.cancelAllRequest()
  }
}
